<template>
  <div class="smz-home-settings" v-loading="loading" v-if="$canAndRulesPass(user, 'home.edit')">
    <page-heading
      heading="Настройка главной страницы"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card>
      <div class="card-header">Общий блок</div>
      <div>Описание</div>
      <wysiwyg
        v-model="settings.general_block_desc"
        :hideModules="{ bold: true }"
        @change="updateSetting('general_block_desc')"
      ></wysiwyg>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">Оценка и развитие персонала</div>

      <div class="card-sub-name">Название блока</div>
      <div><el-input v-model="settings.goals_block_name" @change="updateSetting('goals_block_name')"></el-input></div>

      <div class="card-sub-header">Блок для Сотрудника</div>
      <div class="card-sub-name">Название блока для Сотрудника</div>
      <div><el-input v-model="settings.goals_employee_block_name" @change="updateSetting('goals_employee_block_name')"></el-input></div>

      <div class="card-sub-name">Описание блока для Сотрудника</div>
      <wysiwyg
        v-model="settings.goals_employee_block_desc"
        :hideModules="{ bold: true }"
        @change="updateSetting('goals_employee_block_desc')"
      ></wysiwyg>

      <div class="card-sub-name">Полезная информация</div>
      <HomeSettingsFiles type="goals_employee"></HomeSettingsFiles>




      <div class="card-sub-header">Блок для Руководителя</div>
      <div class="card-sub-name">Название блока для Руководителя</div>
      <div><el-input v-model="settings.goals_chief_block_name" @change="updateSetting('goals_chief_block_name')"></el-input></div>

      <div class="card-sub-name">Описание блока для Руководителя</div>
      <wysiwyg
        v-model="settings.goals_chief_block_desc"
        :hideModules="{ bold: true }"
        @change="updateSetting('goals_chief_block_desc')"
      ></wysiwyg>

      <div class="card-sub-name">Полезная информация</div>
      <HomeSettingsFiles type="goals_chief"></HomeSettingsFiles>
    </el-card>


    <el-card style="margin-top: 20px">
      <div class="card-header">Компенсации</div>

      <div class="card-sub-name">Название блока</div>
      <div><el-input v-model="settings.compensations_block_name" @change="updateSetting('compensations_block_name')"></el-input></div>

      <div class="card-sub-header">Блок для Компенсационных менеджеров</div>
      <div class="card-sub-name">Название блока для Компенсационных менеджеров</div>
      <div><el-input v-model="settings.compensations_managers_block_name" @change="updateSetting('compensations_managers_block_name')"></el-input></div>

      <div class="card-sub-name">Описание блока для Компенсационных менеджеров</div>
      <wysiwyg
        v-model="settings.compensations_managers_block_desc"
        :hideModules="{ bold: true }"
        @change="updateSetting('compensations_managers_block_desc')"
      ></wysiwyg>

      <div class="card-sub-name">Полезная информация</div>
      <HomeSettingsFiles type="compensations_managers"></HomeSettingsFiles>
    </el-card>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import requestSender from "@/api/base/requestSender";
import HomeSubBlockWithFiles from "@/componentsCompany/smz/home/HomeSubBlockWithFiles.vue";
import HomeBlockButton from "@/componentsCompany/smz/home/HomeBlockButton.vue";
import PageHeading from "@/components/PageHeading.vue";
import HomeSettingsFiles from "@/componentsCompany/smz/home/HomeSettingsFiles.vue";

export default {
  name: 'HomeSettings',
  components: {HomeSettingsFiles, PageHeading, HomeBlockButton, HomeSubBlockWithFiles},

  data() {
    return {
      loading: false,
      settings: {},
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    loadSettings() {
      this.loading = true;

      requestSender('get', 'home/get-blocks-info')
        .then(data => {
          this.settings = data.settings;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateSetting(name){
      this.loading = true;

      requestSender('post', 'home/save-block-info', {
        name: name,
        value: this.settings[name]
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Настройка успешно сохранена'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}

</script>

<style lang="scss">
.smz-home-settings{

  .card-header{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }
  .card-sub-header{
    margin: 50px 0 10px 0;
    font-weight: bold;
    font-size: 16px;
  }
  .card-sub-name{
    margin: 20px 0 5px 0;
  }
}
</style>